import React from 'react';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// redux
// routes
import { PATH_COMPANY } from 'routes/paths';
// @types
// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import EmptyContent from 'components/EmptyContent';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CompanyListHead, CompanyListToolbar } from 'components/_dashboard/companies';
import Page from 'components/Page';
import LoadingScreen from 'components/LoadingScreen';
import IcmsFilter from '../Components/filter';
import { AnalyzerViewProps } from '../Model';
import Rows from '../Components/rows';

const View: React.FC<AnalyzerViewProps> = ({
  handleChangeRowsPerPage,
  handleFilter,
  setPage,
  getNfs,
  valorTotalIcmsst,
  valorTotalNota,
  TABLE_HEAD,
  TABLE_HEAD_ITENS,
  emptyRows,
  filter,
  filteredList,
  isLoading,
  isNofilterResult,
  page,
  rowsPerPage,
  currentLanguage,
  companySelected,
  listCompanies,
  cnpj,
  rememberValues
}) => (
  <Page title="Analisador">
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Analisador ICMS MT"
        links={[
          { name: 'Home', href: PATH_COMPANY.general.home },
          { name: 'Analisador de ICMS MT' }
        ]}
      />

      {isLoading ? (
        <div style={{ marginTop: '300px' }}>
          <LoadingScreen />
        </div>
      ) : (
        <>
          <IcmsFilter
            getNfs={getNfs}
            listCompanies={listCompanies}
            currentLanguage={currentLanguage}
            cnpj={cnpj}
            rememberValues={rememberValues}
          />
          {companySelected && (
            <Card sx={{ mt: 5 }}>
              <CompanyListToolbar
                filterCompany={filter}
                onFilterCompany={handleFilter}
                numSelected={-1}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 600 }}>
                  <Table>
                    {!isNofilterResult || filter ? (
                      <>
                        {!isNofilterResult && <CompanyListHead headLabel={TABLE_HEAD} />}
                        <TableBody>
                          {filteredList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((nota, key) => (
                              <Rows
                                nota_fiscal={nota}
                                key={key}
                                TABLE_HEAD_ITENS={TABLE_HEAD_ITENS}
                                valorTotalIcmsst={valorTotalIcmsst}
                                valorTotalNota={valorTotalNota}
                              />
                            ))}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {isNofilterResult && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filter} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </>
      )}
    </Container>
  </Page>
);
export default View;
