import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, Button, Grid } from '@mui/material';
import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import endpoints from 'services/request/endpoints';
import { IcmsFilterProps } from '../Model';

import type {} from '@mui/x-date-pickers/themeAugmentation';

const IcmsFilter: React.FC<IcmsFilterProps> = ({
  getNfs,
  currentLanguage,
  cnpj,
  rememberValues
}) => {
  const ValidationSchema = Yup.object().shape({
    initialDate: Yup.date().nullable(),
    finalDate: Yup.date()
      .nullable()
      .min(Yup.ref('initialDate'), 'A data final deve ser posterior à data inicial.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: rememberValues,
    onSubmit: async (values) => {
      const { initialDate, finalDate } = values;
      const company = cnpj;
      let parameters = '';

      const data_inicio = dayjs(initialDate).format('YYYY-MM-DD');
      const data_fim = dayjs(finalDate).format('YYYY-MM-DD');

      if (company) {
        if (initialDate || finalDate) {
          if (initialDate && finalDate) {
            parameters = `?empresa=${company}&data_inicio=${data_inicio}&data_fim=${data_fim}`;
          }
          if (initialDate && !finalDate) {
            parameters = `?empresa=${company}&data_inicio=${data_inicio}`;
          }
          if (!initialDate && finalDate) {
            parameters = `?empresa=${company}&data_inicio=${data_inicio}`;
          }
        } else {
          parameters = `?empresa=${company}`;
        }
      } else {
        if (initialDate && finalDate) {
          parameters = `?data_inicio=${data_inicio}&data_fim=${data_fim}`;
        }
        if (initialDate && !finalDate) {
          parameters = `?data_inicio=${data_inicio}`;
        }
        if (!initialDate && finalDate) {
          parameters = `?data_fim=${finalDate}`;
        }
        if (!initialDate && !finalDate) {
          parameters = '';
        }
      }
      const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.analyzer}${parameters}`;
      getNfs(endpoint, values);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Card sx={{ p: 3 }}>
          <Stack direction="row">
            <Grid container spacing={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
                <Grid item xs={4}>
                  <DatePicker
                    label="Data inicial"
                    {...getFieldProps('initialDate')}
                    onChange={(value) => formik.setFieldValue('initialDate', value)}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        inputProps={{ ...props.inputProps, placeholder: 'dd/mm/aaaa' }}
                        autoComplete="off"
                        error={Boolean(touched.initialDate && errors.initialDate)}
                        helperText={touched.initialDate && errors.initialDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Data final"
                    {...getFieldProps('finalDate')}
                    onChange={(value) => formik.setFieldValue('finalDate', value)}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        inputProps={{ ...props.inputProps, placeholder: 'dd/mm/aaaa' }}
                        autoComplete="off"
                        error={Boolean(touched.finalDate && errors.finalDate)}
                        helperText={touched.finalDate && errors.finalDate}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={4}>
                <Button
                  sx={{ width: '100%', height: '56px', fontSize: '16px' }}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Analisar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default IcmsFilter;
