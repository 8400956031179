import { useAppDispatch } from 'services/request/baseQuery';
import { fetchClientRecovery } from 'services/store/actions/client/auth.action';
import View from './View';

const ClientResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  async function fetchRecovery(cpf: string) {
    const response = await dispatch(fetchClientRecovery({ cpf }));
    return response;
  }

  return <View {...{ fetchRecovery }} />;
};
export default ClientResetPassword;
