import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { ClientRecoveryPayloadProps } from 'services/request/models/payload';
import { ClientRecoveryResponseProps } from 'services/request/models/response';

export const fetchClientRecovery = createAsyncThunk<
  ClientRecoveryResponseProps,
  ClientRecoveryPayloadProps
>('authentication.admin.fetchClientRecovery', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response: AxiosResponse = await api({
      baseURL: process.env.REACT_APP_BASE_URL + endpoints.client.recovery,
      method: 'POST',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
