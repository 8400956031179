/* eslint-disable prefer-const */
import * as Yup from 'yup';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Stack, TextField, Autocomplete } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { useContextCompany } from 'contexts/CompanyContext';
import { termMock } from 'components/_dashboard/companies/termMock';
import { useAppDispatch } from 'services/request/baseQuery';
import { useSnackbar } from 'notistack5';
import { fetchCreateCompany } from 'services/store/actions/company.action';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import InputMask from 'react-input-mask';
import { CobrancaProps } from '../../Model';

const brazilStates = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
];

const listMethodsPayment = [
  {
    value: 1,
    label: 'Boleto'
  },
  {
    value: 2,
    label: 'Cartão de Crédito'
  },
  {
    value: 6,
    label: 'Pix Recebimento'
  }
];

const NewCompanySchema = Yup.object().shape({
  plans: Yup.object().nullable().required('O Plano é obrigatório.'),
  method_payment: Yup.object().required('O Método de pagamento é obrigatório.')
});

const NewCardSchema = Yup.object().shape({
  cardName: Yup.string().required('O Nome é obrigatório.'),
  cardNumber: Yup.string().required('O Cartão é obrigatório.'),
  cardExpired: Yup.string().required('A Validade é obrigatória.'),
  cardCvv: Yup.string().required('O CVV é obrigatório.')
});

const Cobranca: FC<CobrancaProps> = ({
  listPlans,
  handleBack,
  autoCompleteBillingData
}): ReactElement => {
  const { company, setCompany } = useContextCompany();
  const [termModal, setTermModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [handleModal, setHandleModal] = useState(false);
  const [cardValues, setCardValues] = useState({
    cartao_nome: '',
    cartao_numero: '',
    cartao_expira: '',
    cartao_codigo: ''
  });
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { address, bairro, cep, city, complemento, number, state } = autoCompleteBillingData;

  const closeDialog = () => {
    setHandleModal(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      plans: '',
      addressCharge: address,
      numberCharge: number,
      complementoCharge: complemento,
      bairroCharge: bairro,
      zipCodeCharge: cep,
      cityCharge: city,
      stateCharge: state,
      method_payment: ''
    },
    validationSchema: NewCompanySchema,
    onSubmit: async (values: any, { setSubmitting, resetForm, setErrors }) => {
      let dataCobranca = {};

      let cobranca = {
        cobranca: {
          plano_id: values.plans.value || '',
          cobranca_endereco: values.addressCharge || '',
          cobranca_numero: values.numberCharge || '',
          cobranca_complemento: values.complementoCharge || '',
          cobranca_bairro: values.bairroCharge || '',
          cobranca_cep: values.zipCodeCharge || '',
          cobranca_cidade: values.cityCharge || '',
          cobranca_estado: values.stateCharge || '',
          metodo_pagamento: values.method_payment.value || ''
        }
      };

      if (cardValues.cartao_nome !== '') {
        dataCobranca = {
          cobranca: {
            ...cobranca.cobranca,
            cartao: { ...cardValues }
          }
        };
      } else {
        dataCobranca = cobranca;
      }

      const payload: any = {
        cnpj: company.cnpj || '',
        razao: company.razao || '',
        fantasia: company.fantasia || '',
        endereco: company.endereco || '',
        numero: company.numero || '',
        complemento: company.complemento || '',
        cep: company.cep || '',
        bairro: company.bairro || '',
        cidade: company.cidade || '',
        estado: company.estado || '',
        regime_tributario: company.regime_tributario || '',
        contribuinte_icms: company.contribuinte_icms || '',
        fundado_em: company.fundado_em || '',
        email_cnpj: company.email_cnpj || '',
        mei: company.mei,
        nome_contato: company.nome_contato || '',
        telefone_celular: company.telefone_celular || '',
        email_contato: company.email_contato || '',
        senha: company.senha || '',
        chegou_via: company.chegou_via || '',
        aceite_termos: company.aceite_termos || '',
        telefone_fixo: company.telefone_fixo || '',
        ...dataCobranca
      };

      setCompany(payload);

      try {
        const { meta, payload: payloadResponse } = await dispatch(fetchCreateCompany(payload));
        const { requestStatus } = meta;

        if (requestStatus === 'fulfilled') {
          enqueueSnackbar('Cadastro efetuado com sucesso!', {
            variant: 'success'
          });
          navigate('/');
        } else {
          const errorResponse = payloadResponse as AxiosError<Error>;
          setSubmitting(false);
          enqueueSnackbar(errorResponse.response?.data.message || 'Ocorreu um problema.', {
            variant: 'error'
          });
        }
      } catch (error: any) {
        setSubmitting(false);
        setErrors(error as any);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (company.cobranca?.plano_id) {
      const filterPlan = listPlans.find((el) => el.id === company.cobranca?.plano_id);

      formik.setFieldValue('plans', { value: filterPlan?.id, label: filterPlan?.descricao });
    }
  }, [company]);

  const formikCreditCard = useFormik({
    initialValues: {
      cardName: '',
      cardNumber: '',
      cardExpired: '',
      cardCvv: ''
    },
    validationSchema: NewCardSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setCardValues({
        cartao_nome: values.cardName,
        cartao_codigo: values.cardCvv,
        cartao_expira: values.cardExpired,
        cartao_numero: values.cardNumber
      });
      setSubmitting(false);
      setHandleModal(false);
    }
  });

  const renderDialogChangePassword = () => (
    <Dialog maxWidth="sm" fullWidth open={handleModal}>
      <FormikProvider value={formikCreditCard}>
        <DialogContent>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Typography variant="subtitle1">Adicionar Cartão</Typography>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Nome do Cartão"
                  {...formikCreditCard.getFieldProps('cardName')}
                  error={Boolean(
                    formikCreditCard.touched.cardName && formikCreditCard.errors.cardName
                  )}
                  helperText={formikCreditCard.touched.cardName && formikCreditCard.errors.cardName}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputMask
                  mask="9999 9999 9999 9999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('cardNumber')}
                  error={Boolean(
                    formikCreditCard.errors.cardNumber && formikCreditCard.touched.cardNumber
                  )}
                  helperText={
                    formikCreditCard.touched.cardNumber && formikCreditCard.errors.cardNumber
                  }
                >
                  {(props: any) => (
                    <TextField
                      label="Número do Cartão"
                      fullWidth
                      {...props}
                      sx={{ fontSize: '0.875rem' }}
                    />
                  )}
                </InputMask>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputMask
                  formatChars={{ '1': '[0-1]', '9': '[0-9]' }}
                  mask="19/9999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('cardExpired')}
                  error={Boolean(
                    formikCreditCard.errors.cardExpired && formikCreditCard.touched.cardExpired
                  )}
                  helperText={
                    formikCreditCard.touched.cardExpired && formikCreditCard.errors.cardExpired
                  }
                >
                  {(props: any) => (
                    <TextField
                      label="Data de Validade"
                      fullWidth
                      {...props}
                      placeholder="MM/AAAA"
                      sx={{ fontSize: '0.875rem' }}
                      size="small"
                    />
                  )}
                </InputMask>

                <InputMask
                  mask="999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('cardCvv')}
                  error={Boolean(
                    formikCreditCard.errors.cardCvv && formikCreditCard.touched.cardCvv
                  )}
                  helperText={formikCreditCard.touched.cardCvv && formikCreditCard.errors.cardCvv}
                >
                  {(props: any) => (
                    <TextField
                      label="CVV"
                      fullWidth
                      {...props}
                      sx={{ fontSize: '0.875rem' }}
                      size="small"
                    />
                  )}
                </InputMask>
              </Stack>
            </Stack>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={() => closeDialog()}>
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            type="button"
            loading={formikCreditCard.isSubmitting}
            onClick={() => formikCreditCard.handleSubmit()}
          >
            Enviar
          </LoadingButton>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listPlans}
                    sx={{ width: 550 }}
                    {...getFieldProps('plans')}
                    onChange={(event, value) => formik.setFieldValue('plans', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Planos"
                        error={Boolean(touched.plans && errors.plans)}
                        helperText={touched.plans && errors.plans}
                      />
                    )}
                  />

                  <Autocomplete
                    options={listMethodsPayment}
                    sx={{ width: 550 }}
                    id="method_payment"
                    {...getFieldProps('method_payment')}
                    onChange={(event, value) => {
                      if (value.value !== 2) {
                        setCardValues({
                          cartao_nome: '',
                          cartao_numero: '',
                          cartao_expira: '',
                          cartao_codigo: ''
                        });
                        formikCreditCard.resetForm();
                      }
                      setHandleModal(value.value === 2);
                      formik.setFieldValue('method_payment', value);
                    }}
                    renderInput={(methodPaymentPros: any) => (
                      <TextField
                        {...methodPaymentPros}
                        label="Métodos de pagamento"
                        error={Boolean(touched.method_payment && errors.method_payment)}
                        helperText={touched.method_payment && errors.method_payment}
                      />
                    )}
                  />

                  {renderDialogChangePassword()}
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                  <InputMask mask="99999-999" maskChar=" " {...getFieldProps('zipCodeCharge')}>
                    {(inputProps: any) => <TextField {...inputProps} fullWidth label="CEP" />}
                  </InputMask>

                  <TextField
                    sx={{ width: 550 }}
                    fullWidth
                    label="Endereço (Rua)"
                    {...getFieldProps('addressCharge')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                  <TextField
                    sx={{ width: 550 }}
                    fullWidth
                    label="Número"
                    {...getFieldProps('numberCharge')}
                  />

                  <TextField
                    sx={{ width: 550 }}
                    fullWidth
                    label="Complemento"
                    {...getFieldProps('complementoCharge')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                  <TextField fullWidth label="Bairro" {...getFieldProps('bairroCharge')} />

                  <TextField fullWidth label="Cidade" {...getFieldProps('cityCharge')} />

                  <Autocomplete
                    options={brazilStates}
                    {...getFieldProps('stateCharge')}
                    onChange={(event, value) => formik.setFieldValue('stateCharge', value)}
                    renderInput={(inputProps) => (
                      <TextField {...inputProps} fullWidth label="Estado" />
                    )}
                  />
                </Stack>

                <div
                  style={{
                    paddingTop: '30px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <LoadingButton variant="contained" type="button" onClick={handleBack}>
                    Voltar
                  </LoadingButton>

                  <div style={{ display: 'flex', columnGap: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox onClick={() => setTermModal(true)} defaultChecked checked={check} />
                      Leia os
                      <button
                        type="button"
                        onClick={() => setTermModal(true)}
                        style={{
                          color: '#00AB55',
                          fontWeight: 600,
                          border: 'none',
                          backgroundColor: 'transparent',
                          fontSize: '14px',
                          cursor: 'pointer',
                          padding: '0 4px'
                        }}
                      >
                        Termos e Condições Gerais
                      </button>
                      da plataforma para aceitar
                    </div>

                    <Dialog
                      open={termModal}
                      onClose={() => setTermModal(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Termos de Uso</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {termMock.split('\n').map((el, key) => (
                            <div style={{ marginBottom: 3 }} key={key}>
                              {el}
                            </div>
                          ))}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          onClick={() => {
                            setTermModal(false);
                            setCheck(false);
                          }}
                        >
                          Não concordo
                        </Button>
                        <Button
                          onClick={() => {
                            setCheck(true);
                            setTermModal(false);
                          }}
                        >
                          Concordo
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      disabled={!check}
                    >
                      Enviar Cadastro
                    </LoadingButton>
                  </div>
                </div>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default Cobranca;
