// routes
import { PATH_COMPANY, PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  register: getIcon('ic_register'),
  calculator: getIcon('ic_calculator'),
  tax: getIcon('ic_tax'),
  aliquot: getIcon('ic_aliquot'),
  analytics: getIcon('ic_analytics'),
  resources: getIcon('ic_resources'),
  key: getIcon('ic_key'),
  whatsapp: getIcon('ic_whatsapp'),
  settings: getIcon('ic_settings'),
  table: getIcon('ic_table_multiple'),
  users: getIcon('ic_users')
};

const sidebarConfig = [
  {
    subheader: 'GESTÃO',
    items: [
      {
        title: 'cadastros',
        path: PATH_DASHBOARD.registers.root,
        icon: ICONS.register,
        children: [{ title: 'Clientes', path: PATH_COMPANY.general.management.clients }]
      }
    ]
  },
  {
    subheader: 'ADMINSITRATIVO',
    items: [
      {
        title: 'Usuários',
        path: PATH_DASHBOARD.registers.root,
        icon: ICONS.users,
        children: [
          { title: 'Grupo Permissões', path: PATH_COMPANY.general.management.groups },
          { title: 'Usuários', path: PATH_COMPANY.general.administrative.users }
        ]
      },
      {
        title: 'Configurações',
        path: '',
        icon: ICONS.settings,
        children: [{ title: 'Faturamento', path: PATH_COMPANY.general.administrative.invoicing }]
      },
      {
        title: 'Tabelas',
        path: '',
        icon: ICONS.table,
        children: [
          { title: 'Alíquota', path: PATH_COMPANY.general.administrative.tables.states },
          { title: 'Anexo X', path: PATH_COMPANY.general.administrative.tables.attachmentxs },
          { title: 'Convênio 5291', path: PATH_COMPANY.general.administrative.tables.conv5291s },
          { title: 'Portaria 195', path: PATH_COMPANY.general.administrative.tables.port195s },
          {
            title: 'Protocolo ICMS 41',
            path: PATH_COMPANY.general.administrative.tables.proticms41s
          },
          { title: 'Segmentos', path: PATH_COMPANY.general.administrative.tables.segments }
        ]
      },
      {
        title: 'Recursos',
        path: '',
        icon: ICONS.resources,
        children: [
          { title: 'Importador', path: PATH_COMPANY.general.administrative.xml },
          { title: 'Analisador', path: PATH_COMPANY.general.administrative.analyzer }
        ]
      }
    ]
  }
];

export default sidebarConfig;
